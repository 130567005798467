import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-8 bg-lightGrey" }
const _hoisted_2 = {
  key: 0,
  class: "text-black text-sm font-medium"
}
const _hoisted_3 = {
  key: 1,
  class: "text-black text-sm font-medium"
}
const _hoisted_4 = { class: "px-5 pb-5" }
const _hoisted_5 = { class: "max-w-sm mx-auto py-20" }
const _hoisted_6 = { class: "flex space-x-4" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiInputText = _resolveComponent("UiInputText")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiModal = _resolveComponent("UiModal")!

  return (_openBlock(), _createBlock(_component_UiModal, { "wrapper-class": "max-w-2xl w-full rounded-lg overflow-hidden" }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.osNumber)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('DASHBOARD_TABLE_POP_UP_CHANGE_OS_TITLE')), 1))
          : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('DASHBOARD_TABLE_POP_UP_ADD_OS_TITLE')), 1))
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_UiInputText, {
            "model-value": _ctx.osNumber,
            label: _ctx.$t('DASHBOARD_TABLE_POP_UP_ADD_OS_LABEL'),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:osNumber', $event)))
          }, null, 8, ["model-value", "label"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_UiButton, {
            class: "py-3 bg-lightGrey text-darkGrey w-full text-sm hover:text-red",
            type: "button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:model-value', false)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('DASHBOARD_TABLE_POP_UP_ADD_OS_CANCEL')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_UiButton, {
            class: "w-full py-3 bg-blue hover:bg-darkBlue text-white",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('confirmChangeOsNumber')))
          }, {
            default: _withCtx(() => [
              (_ctx.osNumber)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('DASHBOARD_TABLE_POP_UP_ADD_OS_CHANGE')), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('DASHBOARD_TABLE_POP_UP_ADD_OS_CONFIRM')), 1))
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}