
import { defineComponent, PropType } from 'vue'
import FilterCounter from '@/components/particles/common/Filter/FilterCounter.vue'
import { FilterCounterInfo } from '@/store/dashboard/types'
export default defineComponent({
  components: {
    FilterCounter,
  },
  props: {
    filterCounterInfo: {
      type: Object as PropType<FilterCounterInfo>,
      default: () => ({}),
    },
    searchRequestOne: {
      type: String,
      default: '',
    },
    searchRequestTwo: {
      type: String,
      default: '',
    },
    searchRequestThree: {
      type: String,
      default: '',
    },
    searchRequestFour: {
      type: String,
      default: '',
    },
    searchRequestFive: {
      type: String,
      default: '',
    },
  },
  emits: ['update:searchRequestOne', 'update:searchRequestTwo','update:searchRequestThree','update:searchRequestFour','update:searchRequestFive', 'onFilter'],
  setup() {
    return {}
  },
})
