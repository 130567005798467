
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    osNumber: {
      type: String,
      default: '',
    },
  },
  emits: ['update:model-value', 'confirmChangeOsNumber', 'update:osNumber'],
})
