
import { defineComponent, ref, PropType } from 'vue'
import {
  DashboardInfoStatuses,
  DashboardInfoItem,
} from '@/store/dashboard/types'
import DashBoardOsModal from '@/components/particles/DashboardView/DashBoardTableOsModal.vue'
import DashBoardNoteModal from '@/components/particles/DashboardView/DashboardTableNoteModal.vue'
import { PersonalInfo } from '@/store/settings/types'
import { newNoteRequest } from '@/store/dashboard/types'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'DashboardTable',
  components: { DashBoardOsModal, DashBoardNoteModal },
  props: {
    tableInfo: {
      type: Array as PropType<DashboardInfoItem[]>,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    headerType: {
      type: String,
      required: true,
      default: '',
    },
    headerClient: {
      type: String,
      required: true,
      default: '',
    },
    headerResidence: {
      type: String,
      required: true,
      default: '',
    },
    headerBuilding: {
      type: String,
      required: true,
      default: '',
    },
    headerStatus: {
      type: String,
      required: true,
      default: '',
    },
    numberOfVisibleRows: {
      type: Number,
      default: 10,
    },
    personalData: {
      type: Object as PropType<PersonalInfo>,
      default: () => ({}),
    },
  },
  emits: [
    'update:headerType',
    'update:headerClient',
    'update:headerResidence',
    'update:headerBuilding',
    'update:headerStatus',
    'update:numberOfVisibleRows',
    'update:isAddOsNumberVisible',
    'update:osNumber',
    'sendNewNote',
    'sendNewOsNumber',
  ],

  setup(props, { emit }) {
    const { t } = useI18n()

    const filteredSelectType = () => {
      return [
        ...new Set(
          props.tableInfo.map((value: DashboardInfoItem) => value.type)
        ),
      ]
    }
    const filteredSelectClient = () => {
      return [
        ...new Set(
          props.tableInfo.map(
            (value: DashboardInfoItem) => value.device.residence.client?.name
          )
        ),
      ]
    }

    const filteredSelectResidence = () => {
      return [
        ...new Set(
          props.tableInfo.map(
            (value: DashboardInfoItem) => value.device.residence.parameters.name
          )
        ),
      ]
    }

    const filteredSelectBuilding = () => {
      return [
        ...new Set(
          props.tableInfo.map(
            (value: DashboardInfoItem) => value.device.space.parameters.name
          )
        ),
      ]
    }

    //Statuses should be in understandable view
    const filteredSelectStatus = () =>
      [
        ...new Set(
          props.tableInfo.map((value: DashboardInfoItem) => value.status)
        ),
      ].map((item) =>
        item === DashboardInfoStatuses.READY_TO_SEND
          ? t('DASHBOARD_TABLE_STATUS_READY_TO_SEND')
          : item === DashboardInfoStatuses.SENT_TO_PROVIDER
          ? t('DASHBOARD_TABLE_STATUS_SENT_TO_PROVIDER')
          : item === DashboardInfoStatuses.SENT_TO_MANAGER
          ? t('DASHBOARD_TABLE_STATUS_SENT_TO_MANAGER')
          : item === DashboardInfoStatuses.TRANSFERED
          ? t('DASHBOARD_TABLE_STATUS_TRANSFERED')
          : item === DashboardInfoStatuses.COMPLETED
          ? t('DASHBOARD_TABLE_STATUS_COMPLETED')
          : item === DashboardInfoStatuses.CANCELLED
          ? t('DASHBOARD_TABLE_STATUS_CANCELLED')
          : item
      )

    const showHiddenInfoBlock = (row: DashboardInfoItem) => {
      //Adds an IsOpened Field which is necessary to show/close hidden block in UiTable
      if (row.IsOpened === undefined || row.IsOpened === false)
        row.IsOpened = true
      else row.IsOpened = false
    }

    const osNumber = ref('')
    const isAddOsNumberVisible = ref(false)
    const isAddNoteVisible = ref(false)
    const rowToChange = ref()

    const showOsNumberModal = (row: DashboardInfoItem) => {
      rowToChange.value = row
      osNumber.value = row.service_order_number
      isAddOsNumberVisible.value = true
    }

    const showAddNoteModal = (row: DashboardInfoItem) => {
      rowToChange.value = row
      isAddNoteVisible.value = true
    }

    const confirmChangeOsNumber = () => {
      emit('sendNewOsNumber', {
        id: rowToChange.value.id,
        serviceOrderNumber: osNumber.value,
      })
      isAddOsNumberVisible.value = false
    }
    const confirmAddNewNote = (noteText: newNoteRequest) => {
      emit('sendNewNote', { id: rowToChange.value.id, text: noteText.text })
      isAddNoteVisible.value = false
    }

    return {
      DashboardInfoStatuses,
      filteredSelectType,
      filteredSelectClient,
      filteredSelectResidence,
      filteredSelectBuilding,
      filteredSelectStatus,
      showHiddenInfoBlock,
      osNumber,
      isAddOsNumberVisible,
      showOsNumberModal,
      confirmChangeOsNumber,
      confirmAddNewNote,
      showAddNoteModal,
      isAddNoteVisible,
    }
  },
})
