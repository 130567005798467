
import { defineComponent, computed, ref } from 'vue'
import useStore from '@/store'
import { DASHBOARD_ACTION_TYPES } from '@/store/dashboard/actions'
import { useCancelToken } from '@/hooks/useCancelToken'
import ViewLayout from '@/components/particles/common/ViewLayout.vue'
import Filter from '@/components/particles/DashboardView/Filter.vue'
import LoadingSpinner from '@/components/particles/common/LoadingSpinner.vue'
import DashboardTable from '@/components/particles/DashboardView/DashboardTable.vue'
import { dashboardTableHeaders } from '@/helpers/tableHeaders'
import { USER_SETTINGS_ACTION_TYPES } from '@/store/settings/actions'
import { newNoteRequest, newOsNumberRequest } from '@/store/dashboard/types'

export default defineComponent({
  name: 'Home',
  components: { Filter, LoadingSpinner, DashboardTable, ViewLayout },
  setup() {
    const store = useStore()
    const getDashboardCancelToken = useCancelToken()
    const getPersonalInfoCancelToken = useCancelToken()
    const headerType = ref()
    const headerClient = ref()
    const headerResidence = ref()
    const headerBuilding = ref()
    const headerStatus = ref()
    const filterString = ref('')
    const perPage = ref()

    

    const tableFilters = computed(() => [
      headerType.value,
      headerResidence.value,
      headerClient.value,
      headerBuilding.value,
      headerStatus.value,
      filterString.value,
    ])

    const personalData = computed(() => store.state.userSettings.personalInfo)
    const dashboardList = computed(() => store.state.dashboard.dashboardInfo)
    const counterInfo = computed(() => store.state.dashboard.counterInfo)
    const isLoaded = computed(() => store.state.dashboard.isLoaded)

    //filter
    const livingHouses = ref()
    const livingObjects = ref()

    const getDashboardInfo = async () => {
      try {
        await Promise.all([
          store.dispatch(
            DASHBOARD_ACTION_TYPES.GET_DASHBOARD_INFO,
            getDashboardCancelToken.value
          ),
          
          console.log("store.state.dashboard.isLoaded1", store.state.dashboard.isLoaded),
          // store.dispatch(
          //   USER_SETTINGS_ACTION_TYPES.GET_PERSONAL_INFO,
          //   getPersonalInfoCancelToken.value
          // ),          
          console.log("store.state.dashboard.isLoaded2", store.state.dashboard.isLoaded),
        ])
      } catch (error) {
        console.log('error: ', error)
      }
      console.log("store.state.dashboard.isLoaded3", store.state.dashboard.isLoaded)
    }
    getDashboardInfo()

    const handleFilter = () => {
      //TODO: Filter by livingHouses.value and/or livingObjects.value
    }

    const onSendingNewNote = async (newNote: newNoteRequest) => {
      await store.dispatch(DASHBOARD_ACTION_TYPES.SEND_NEW_NOTE, newNote)
      getDashboardInfo()
    }

    const onSendingNewOsNumber = async (newOsNumber: newOsNumberRequest) => {
      await store.dispatch(
        DASHBOARD_ACTION_TYPES.SEND_NEW_OS_NUMBER,
        newOsNumber
      )
      getDashboardInfo()
    }

    return {
      dashboardList,
      counterInfo,
      isLoaded,
      perPage,
      tableFilters,
      headerType,
      headerClient,
      headerResidence,
      headerBuilding,
      headerStatus,
      filterString,
      dashboardTableHeaders,
      livingHouses,
      livingObjects,
      handleFilter,
      personalData,
      onSendingNewNote,
      onSendingNewOsNumber,
    }
  },
})
