import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Filter = _resolveComponent("Filter")!
  const _component_DashboardTable = _resolveComponent("DashboardTable")!
  const _component_ViewLayout = _resolveComponent("ViewLayout")!

  return (_openBlock(), _createBlock(_component_ViewLayout, null, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_LoadingSpinner, { class: "h-screen" }, null, 512), [
        [_vShow, _ctx.isLoaded]
      ]),
      (!_ctx.isLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Filter, {
              class: "mb-5",
              searchRequestOne: _ctx.livingHouses,
              "onUpdate:searchRequestOne": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.livingHouses) = $event)),
              searchRequestTwo: _ctx.livingObjects,
              "onUpdate:searchRequestTwo": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.livingObjects) = $event)),
              filterCounterInfo: _ctx.counterInfo,
              onOnFilter: _ctx.handleFilter
            }, null, 8, ["searchRequestOne", "searchRequestTwo", "filterCounterInfo", "onOnFilter"]),
            _createVNode(_component_DashboardTable, {
              headerType: _ctx.headerType,
              "onUpdate:headerType": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.headerType) = $event)),
              headerClient: _ctx.headerClient,
              "onUpdate:headerClient": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.headerClient) = $event)),
              headerResidence: _ctx.headerResidence,
              "onUpdate:headerResidence": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.headerResidence) = $event)),
              headerBuilding: _ctx.headerBuilding,
              "onUpdate:headerBuilding": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.headerBuilding) = $event)),
              headerStatus: _ctx.headerStatus,
              "onUpdate:headerStatus": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.headerStatus) = $event)),
              headers: _ctx.dashboardTableHeaders,
              filters: _ctx.tableFilters,
              tableInfo: _ctx.dashboardList,
              numberOfVisibleRows: _ctx.perPage,
              personalData: _ctx.personalData,
              "onUpdate:numberOfVisibleRows": _cache[7] || (_cache[7] = ($event: any) => (_ctx.perPage = $event)),
              onSendNewNote: _ctx.onSendingNewNote,
              onSendNewOsNumber: _ctx.onSendingNewOsNumber
            }, null, 8, ["headerType", "headerClient", "headerResidence", "headerBuilding", "headerStatus", "headers", "filters", "tableInfo", "numberOfVisibleRows", "personalData", "onSendNewNote", "onSendNewOsNumber"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}