
import { defineComponent, ref, PropType } from 'vue'
import { PersonalInfo } from '@/store/settings/types'
import NativeCheckbox from '@/components/UI/NativeCheckbox/NativeCheckbox.vue'
export default defineComponent({
  props: {
    personalData: {
      type: Object as PropType<PersonalInfo>,
      default: () => ({}),
    },
  },
  emits: ['update:model-value', 'confirmAddNewNote'],
  setup(props) {
    const newNote = ref({
      created_at: new Date(Date.now()),
      created_by: {
        name: `${props.personalData.firstname}`,
        last_name: `${props.personalData.lastname}`,
      },
      text: '',
    })
    return { newNote }
  },
  components: { NativeCheckbox },
})
